<template>
  <div class="relative" @mouseover="openHover" @mouseout="delayedHoverOff">
    <!-- Guest circle -->
    <div class="flex">
      <div class="flex">
        <div
          class="relative"
          :class="eventStatus == 'pastEvent' ? 'opacity-50' : ''"
          style="margin-left: 0.1rem"
        >
          <!-- Initials / face -->
          <div
            class="
              shadow-sm
              guestcircle
              flex
              overflow-hidden
              rounded-full
              items-center
              justify-center
              bg-grey4
              relative
            "
            :class="guest.responseStatus + ' ' + sizeComponent"
          >
            <svg
              class="h-full w-full absolute"
              preserveAspectRatio="none"
              viewBox="0 0 100 100"
            >
              <!-- Move, Curve (control, control, dest), Line, Fill -->
              <path
                d="M-31.31,57.72 C51.06,72.53 47.11,16.28 105.25,29.11 L116.53,-112.00 L-34.14,-55.75 Z"
                class="guestsvgwave"
                opacity="0.3"
                fill-opacity="null"
                stroke-opacity="null"
                fill="#fff"
              />
            </svg>
            <div
              v-if="!profileImageUrl"
              class="guestinitial text-white uppercase"
              data-recording-sensitive
            >
              {{ initials }}
            </div>
            <div
              v-if="profileImageUrl"
              class="pic_url rounded-full bg-cover bg-center"
              :style="'background-image: url(' + profileImageUrl + ')'"
            ></div>
          </div>

          <!-- Online indicator -->
          <span
            class="h-2 w-2 absolute ml-5 top-0"
            style="margin-top: -0.5rem"
            v-show="online && showOnlineStatus"
          >
            <span
              class="
                animate-ping
                absolute
                inline-flex
                h-2
                w-2
                rounded-full
                bg-alertvalidation
                opacity-75
              "
              style="margin-top: 0.47rem"
            ></span>
            <span
              class="
                relative
                inline-flex
                rounded-full
                h-2
                w-2
                bg-alertvalidation
              "
            ></span>
          </span>
        </div>
      </div>
      <div v-if="showName" class="flex pt-1 ml-2" style="width: 9.5rem">
        <div class="truncate text-sm text-grey2">
          {{ nameLabel }}
        </div>
        <div class="text-sm text-grey4">
          {{ guest.organizer ? 'host' : '' }}
        </div>
        <div
          v-if="profileImageUrl"
          class="pic_url rounded-full bg-cover bg-center z-10"
          :style="'background-image: url(' + profileImageUrl + ')'"
        ></div>
      </div>
    </div>
    <!-- Hover card -->
    <transition name="guesthover">
      <!-- -(0.5*9.6) + (0.5*2.0) (h-8)-->
      <div
        class="absolute z-30"
        :style="fromCapList ? 'left:-10.6rem; top:-0.5rem' : 'left:-3.8rem'"
        v-if="showGuestHover && showDetails"
        @click.prevent
        data-recording-sensitive
      >
        <!-- Arrow up-->
        <div
          v-if="!fromCapList"
          class="
            arrowguest
            h-0
            w-0
            border-solid border-l-4 border-r-4 border-t-8 border-b-8
            mx-auto
            -mt-2
            text-grey4
          "
          :class="guest.responseStatus"
          style="
            border-left-color: rgba(255, 255, 255, 0);
            border-right-color: rgba(255, 255, 255, 0);
            border-top-color: rgba(255, 255, 255, 0);
            background-clip: padding-box;
            border-bottom-color: currentColor;
          "
        ></div>
        <!-- Arrow right-->
        <div
          v-if="fromCapList"
          class="
            arrowguest
            absolute
            r-0
            h-0
            w-0
            border-solid border-l-8 border-r-8 border-t-4 border-b-4
            text-grey4
          "
          :class="guest.responseStatus"
          style="
            border-bottom-color: rgba(255, 255, 255, 0);
            border-right-color: rgba(255, 255, 255, 0);
            border-top-color: rgba(255, 255, 255, 0);
            background-clip: padding-box;
            border-left-color: currentColor;
            right: -1rem;
            top: 1.2rem;
          "
        ></div>

        <!-- Card -->
        <div
          class="bg-base4 rounded-xl shadow-sm pb-3"
          style="width: 9.6rem"
          data-recording-sensitive
        >
          <!-- Wave banner -->
          <div
            class="
              guesthoverwave
              flex flex-wrap
              overflow-hidden
              items-end
              w-full
              h-12
              rounded-t-xl
              bg-grey4
            "
            :class="guest.responseStatus"
          >
            <svg
              class="h-full w-full"
              preserveAspectRatio="none"
              viewBox="0 0 100 100"
            >
              <!-- Move, Curve (control, control, dest), Line, Fill -->
              <path
                d="M-31.31,57.72 C51.06,72.53 47.11,16.28 105.25,29.11 L116.53,-112.00 L-34.14,-55.75 Z"
                opacity="0.3"
                fill-opacity="null"
                stroke-opacity="null"
                fill="#fff"
              />
            </svg>
          </div>

          <!-- Face cirle -->
          <div
            class="
              guestcircle
              mx-auto
              relative
              flex
              rounded-full
              items-center
              justify-center
              mb-2
              border border-solid border-white
              dark:border-base5
              bg-grey4
            "
            :class="guest.responseStatus"
            style="width: 3.3rem; height: 3.3rem; margin-top: -1.65rem"
          >
            <div
              v-if="!profileImageUrl"
              class="guestinitial text-white uppercase text-xl"
            >
              {{ initials }}
            </div>

            <div
              v-if="profileImageUrl"
              style="width: 3.2rem; height: 3.2rem"
              class="bg-cover bg-center rounded-full"
              :style="'background-image: url(' + profileImageUrl + ')'"
            ></div>
          </div>

          <!-- Text details -->
          <div class="overflow-hidden px-3 text-grey1">
            <div
              v-if="nameLabel !== guest.email"
              title="nameLabel"
              class="text-center text-sm truncate"
            >
              {{ nameLabel }}
            </div>
            <div
              class="text-center text-grey2 text-xs truncate"
              style="margin-top: 0.2rem"
            >
              <a
                @click.stop
                :href="'mailto:' + guest.email"
                :title="guest.email"
                >{{ guest.email }}</a
              >
            </div>
            <div
              class="text-grey1 text-center text-sm mt-2 margin-auto text-grey2"
            >
              Event:
              <span :class="guest.responseStatus + '-status'">{{
                getStatusText
              }}</span>
            </div>
            <div class="text-grey1 text-center text-sm mt-3 margin-auto">
              <span class="h-2 w-2" v-show="showOnlineStatus && online">
                <span
                  class="
                    mt-1
                    animate-ping
                    absolute
                    inline-flex
                    h-2
                    w-2
                    rounded-full
                    bg-alertvalidation
                    opacity-75
                  "
                  v-show="online && showOnlineStatus"
                ></span>
                <span
                  class="relative inline-flex rounded-full h-2 w-2"
                  :class="online ? 'bg-alertvalidation' : 'bg-alerterror'"
                ></span>
                {{ online ? 'Viewing this page' : 'Offline' }}
              </span>
              <!-- <template v-if="guest.organizer">
                <span class="text-grey2" v-show="showOnlineStatus">
                  &amp;
                </span>
                Host
              </template> -->

              <span class="capitalize">
                <span v-if="guest.role" class="text-grey2">&</span>
                {{ guest.role }}
              </span>
            </div>
            <div
              class="
                text-center text-grey2 text-sm
                truncate
                underline
                cursor-pointer
              "
              style="margin-top: 0.2rem"
              v-if="!online"
            >
              <a @click="openShareUrl">Give access</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  name: 'MeetingGuest',
  props: {
    guest: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    showName: {
      type: Boolean,
      required: false,
      default: false,
    },
    online: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOnlineStatus: {
      type: Boolean,
      required: false,
      default: false,
    },
    sizeComponent: {
      type: String,
      required: false,
      default: 'size1',
    },
    eventStatus: {
      type: String,
      required: false,
    },
    fromCapList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showGuestHover: false,
      closeTimer: null,
    };
  },
  mounted() {},
  computed: {
    getStatusText() {
      // "needsAction" - The attendee has not responded to the invitation.
      // "declined" - The attendee has declined the invitation.
      // "tentative" - The attendee has tentatively accepted the invitation.
      // "accepted" - The attendee has accepted the invitation.
      if (this.guest.responseStatus == 'needsAction') return 'not answered';
      else if (this.guest.responseStatus == 'declined') return 'declined';
      else if (this.guest.responseStatus == 'tentative') return 'maybe';
      else if (this.guest.responseStatus == 'accepted') return 'accepted';
      return '';
    },

    meetricProfile() {
      // use if available, you have to fetch it in parent to avoid multiple fetches
      if (this.guest.email)
        return this.$store?.getters.profile(this.guest.email)?.profile;
      return null;
    },
    initials: function () {
      if (this.meetricProfile) {
        if (this.meetricProfile.given_name && this.meetricProfile.family_name) {
          return (
            this.meetricProfile.given_name[0] +
            this.meetricProfile.family_name[0]
          );
        } else if (this.meetricProfile.name) {
          return getInitials(this.meetricProfile.name);
        }
      }
      if (this.guest.displayName) return getInitials(this.guest.displayName);
      if (this.guest.email) return this.guest.email.slice(0, 2);
      return '';
    },
    nameLabel: function () {
      if (this.meetricProfile && this.meetricProfile.name)
        return this.meetricProfile.name;
      if (this.guest.displayName) return this.guest.displayName;
      return this.guest.email;
    },
    profileImageUrl: function () {
      return this.meetricProfile ? this.meetricProfile.image_url : '';
    },
  },
  methods: {
    delayedHoverOff: function () {
      this.closeTimer = setTimeout(() => {
        this.showGuestHover = false;
      }, 200);
    },
    openHover: function () {
      clearTimeout(this.closeTimer);
      this.showGuestHover = true;
    },
    openShareUrl() {
      this.$parent.$emit('shareUrl');
    },
  },
};
function getInitials(name) {
  // https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...name.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
  return initials;
}
</script>
<style>
.accepted {
  @apply bg-alertvalidation;
  @apply text-alertvalidation;
}
.tentative {
  @apply bg-alertwarning;
  @apply text-alertwarning;
}

.declined {
  @apply bg-alerterror;
  @apply text-alerterror;
}

.profile {
  @apply bg-base2;
}

/* Guest styling */
.dark .guestcircle {
  @apply bg-grey6 border-none;
}
.dark .guesthoverwave.accepted {
  @apply bg-alertvalidation;
}
.dark .guesthoverwave.tentative {
  @apply bg-alertwarning;
}
.dark .guesthoverwave.declined {
  @apply bg-alerterror;
}

/* Guest initials */
.dark .guestinitial {
  @apply font-semibold;
}
.dark .accepted .guestinitial,
.dark .accepted.arrowguest,
.accepted-status {
  @apply text-alertvalidation;
}
.dark .tentative .guestinitial,
.dark .tentative.arrowguest,
.tentative-status {
  @apply text-alertwarning;
}
.dark .declined .guestinitial,
.dark .declined.arrowguest,
.declined-status {
  @apply text-alerterror;
}
.dark .guestsvgwave {
  opacity: 0.2;
}
.dark .arrowguestcap {
  color: #3a3a3a;
}

/* Outline on input */
.dark .share-url:focus {
  @apply ring-0;
}

.guesthover-enter-active {
  transition: opacity 0.5s ease;
  will-change: opacity;
}
.guesthover-leave-active {
  transition: opacity 0.2s ease;
  will-change: opacity;
}
.guesthover-enter,
.guesthover-leave-to {
  opacity: 0;
  will-change: opacity;
}

.size1 {
  @apply text-sm w-7 h-7;
}
.size1 .pic_url {
  @apply w-6 h-6;
}
.size2 {
  @apply h-6 w-6 text-xs;
}
.size2 .pic_url {
  width: 1.4rem;
  height: 1.4rem;
}

.size3 {
  width: 3.4rem;
  height: 3.4rem;
  @apply text-xl;
}
.size3 .pic_url {
  width: 3rem;
  height: 3rem;
}
</style>
